@import url(https://fonts.googleapis.com/css?family=Montserrat);

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: -10%;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.loaderdiv svg {
    display: block;
    font: 8em 'Montserrat';
    width: 100%;
    height: 400px;
    margin: 0 auto;
}

.loaderdiv  .text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
}

.loaderdiv  .text-copy:nth-child(1){
	stroke: #16BBEF;
	animation-delay: -1;
}

.loaderdiv .text-copy:nth-child(2){
	stroke: rgb(234 179 8 / 1);
	animation-delay: -2s;
}

.loaderdiv .text-copy:nth-child(3){
	stroke: #94A3B8;
	animation-delay: -3s;
}

.loaderdiv .text-copy:nth-child(4){
	stroke: #5ed0f4;
	animation-delay: -4s;
}

.loaderdiv .text-copy:nth-child(5){
	stroke: #384059;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
}